import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

export type TypeRequestResetPassword = {
    email: string;
    phone: string;
};

/**
 * @route api/reset-password
 * @post
 * @param {TypeRequestRegistration} formData
 */
export const apiRouteResetPassword = async (formData: TypeRequestResetPassword) => {
    const data = new FormData();
    if (formData.email)
        data.append("email", formData.email);
    else if (formData.phone)
        data.append("phone", formData.phone);
    const response = await kyRequest
        .post(`${BACKEND_HOST}/reset-password`, {
            body: data,
        })
        .catch(e => {
            // console.log("apiToken", `??? ::: ${e.name}: ${e.message}`);
            return e;
        });
    return responseCallback(response);
};

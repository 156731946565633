import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "redux-store";
import {getStartupApi, postStartupApi} from "./api";
import {makeBaseReduxAction, makeClearCondition, makeConditionReducers} from "../../helper/redux";
import {Expo} from "../admin-expo/interfaces/admin-expo";

export type Startup = {
    id:number;
    title: string,
    brand: string,
    site: string,
    company: string,
    inn: string,
    role: string,
    description: string,
    reason: string,
    release_state: number,
    proceeds: number,
    proceeds2022: number,
    sector: string,
    target: string,
    competitor: string,
    has_own: number,
    own_type: number
    acceleration: number
    acceleration_result: any,
    city: string,
    amount: number
    team: any,
    authors: any,
    has_investors: number,
    self_amount: string,
    business_file: any,
    pdf_file: any,
    status: {
        state: number,
        message: string,
        created_at: number
    },
};

export const slicerStartups = createSlice({
    name: "startups",
    initialState: {
        items: [],
        item: {} as Startup,
        condition: makeClearCondition(),
    },
    reducers: {
        ...makeConditionReducers(),
        onLoadItem: (state, action) => {
            state.item = action.payload.response.data;
        },
        onLoadList: (state, action) => {
            state.items = action.payload.response.data;
        },
    },
});

export const {
    onRequest,
    onNoticeHide,
    stopLoading,
    onLoadList,
    onLoadItem,
    onFail,
    clearCondition
} = slicerStartups.actions;

const baseStateAsync = makeBaseReduxAction(
    (dispatch: any) => {
        dispatch(onRequest());
    },
    (dispatch, r) => {
        if (r) {
            dispatch(onFail(r));
        }
    },
    dispatch => {
        dispatch(stopLoading());
    },
);

export const asyncLoadStartup = (params?: any, callback?: (result: any) => void) => {
    return baseStateAsync(getStartupApi, params, callback, (dispatch, result) => {
        dispatch(params?.id ? onLoadItem(result) : onLoadList(result));
    });
};

export const asyncSaveStartup = (body: any, callback?: (result: any) => void) => {
    return baseStateAsync(postStartupApi, body, callback, (dispatch, result) => {
    });
};


export const reselectStartup = (state: RootState) => {
    return state.startups.item;
};
export const reselectStartupCondition = (state: RootState) => {
    return state.startups.condition;
};
export const reselectStartups = (state: RootState) => {
    return state.startups.items;
};

export default slicerStartups.reducer;

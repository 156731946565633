import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
    asyncToken,
    reselectToken,
    onChange,
    reselectTokenCondition,
    onNoticeHide,
    onConditionClear,
} from "controllers/token/redux-store/token/token";
import { entityClear } from "controllers/profile-route/redux-store/profile/profile";
import KDWSnackbar from "components/KDWSnackbar/KDWSnackbar";
import __translate from "helper/locale/locale";
import SocialButtons, { styles } from "controllers/registration-route/form/Social/SocialButtons";
import TgButton from "controllers/registration-route/form/Social/TgButton";
import YoutubeButton from "controllers/registration-route/form/Social/YoutubeButton";
import PasswordTextField from "components/Form/Inputs/PasswordTextField";
import { onChange as reChange } from "controllers/reset-password-route/redux-store/reset-password/reset-password";
import GreenLogo from "pages/Account/UserMenu/GreenLogo";
import { toLastPage } from "pages/Auth/PageToken";
import { MANAGER_ROLES as MR } from "../../../../constants/constants";

export const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        marginLeft: "1em",
        marginRight: "1em",
        alignItems: "center",
        background: "#ffffff",
        borderRadius: "3em",
        padding: theme.spacing(8),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
        },
        [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(4),
            paddingTop: theme.spacing(6),
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(2, 5),
        background: "#13C4A4",
        color: "white",
        height: "50px",
        borderRadius: "12px",
        fontWeight: 400,
        width: "100%",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    navLink: {
        marginRight: 8,
    },
    link: {
        color: "#13C4A4",
    },
    checkbox: {
        color: "#13C4A4 !important",
        ":checked": {
            color: "#13C4A4 !important",
            background: "#13C4A4",
        },
    },
    logo: {},
    bg: {
        background: '#00775f url("/static/bg.png") bottom no-repeat !important',
        backgroundPositionX: "left !important",
        minHeight: "100vh",
    },
}));

export default function FormToken() {
    const classes = useStyles();
    const socialClasses = styles();
    const history = useHistory();
    const params = useParams<{ ru: string }>();
    const dispatch = useDispatch();
    const viewToken = useSelector(reselectToken);
    const conditionToken = useSelector(reselectTokenCondition);
    const isFormDisabled = conditionToken.inProgress || conditionToken.notice.severity === "success";
    const search = new URLSearchParams(useLocation().search);
    const email = search.get("email");
    const handleOnChange = (e: any) => {
        dispatch(onChange(e.target));
    };
    const handleOnToken = () => {
        dispatch(
            asyncToken(result => {
                if (result) {
                    setTimeout(() => {
                        if (result.result) {
                            switch (result.data.type) {
                                case "admin": {
                                    history.push(`/${params.ru}/admin/users/1`);
                                    break;
                                }
                                case "manager":
                                    let n = Number.parseInt(result.data.user?.options?.subrole || 0);
                                    if ((n & MR.USER_ROLE_MANAGER) > 0) return history.push(`/moderation/participants`);
                                    else if ((n & MR.ARTICLE_MANAGER) > 0) return history.push(`/moderation/articles`);
                                    else if ((n & MR.FORUM_THEME_EDITOR) > 0)
                                        return history.push(`/moderation/program`);
                                    else if (n === MR.REPORTS_EDITOR) return history.push(`/moderation/reports`);
                                    else toLastPage(history, `/${params.ru}/user/personal-data`);
                                    break;
                                case "user": {
                                    toLastPage(history, `/${params.ru}/user/personal-data`);
                                    break;
                                }
                                default: {
                                    history.push("/");
                                    break;
                                }
                            }
                        }
                    }, 500);
                }
            }),
        );
    };

    React.useEffect(() => {
        handleOnChange({ target: { name: "email", value: email } });
        return () => dispatch(onConditionClear());
    }, [email]);

    return (
        <React.Fragment>
            <KDWSnackbar
                isOpen={conditionToken.notice.visibility}
                severity={conditionToken.notice.severity}
                handleOpen={() => dispatch(onNoticeHide())}
                message={conditionToken.response.message}
            />
            <Grid container className={classes.bg} alignItems="center" justify="center">
                <Grid item md={12} lg={6}>
                    <CssBaseline />
                    <Grid container alignItems="center" justify="center">
                        <Grid item md={7}>
                            <div className={classes.paper}>
                                <Grid item xs={12} md={12} justify={"center"} style={{ display: "flex" }}>
                                    <GreenLogo />
                                </Grid>
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                    }}
                                    className={classes.form}
                                    noValidate>
                                    <TextField
                                        disabled={isFormDisabled}
                                        onChange={e => {
                                            handleOnChange(e);
                                            dispatch(reChange(e.target));
                                        }}
                                        value={viewToken.email}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label={__translate("login.email", "Email")}
                                        name="email"
                                        autoComplete="off"
                                    />
                                    <PasswordTextField
                                        onChange={handleOnChange}
                                        disabled={isFormDisabled}
                                        fullWidth={true}
                                        name="password"
                                        label={__translate("login.pwd", "Пароль")}
                                        value={viewToken.password}
                                    />
                                    <Grid item xs={12}>
                                        <Grid container justify="space-between">
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={isFormDisabled}
                                                            name="rememberMe"
                                                            onChange={handleOnChange}
                                                            checked={viewToken.rememberMe}
                                                            color="primary"
                                                            className={classes.checkbox}
                                                        />
                                                    }
                                                    label={__translate("login.remember", "Запомнить меня")}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{ lineHeight: "42px", display: "flex" }}
                                                justify="flex-end">
                                                <NavLink className={classes.link} to={`/res`}>
                                                    {__translate("login.forgot", "Забыли пароль?")}
                                                </NavLink>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <SocialButtons
                                        accept={true}
                                        rootStyles={{ justifyContent: "center", alignItems: "center" }}
                                        left={true}
                                    />
                                    <Grid item style={{ textAlign: "center" }} xs={12}>
                                        <Button
                                            disabled={isFormDisabled}
                                            onClick={e => {
                                                e.preventDefault();
                                                dispatch(entityClear());
                                                handleOnToken();
                                            }}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}>
                                            {__translate("login.login_btn", "Войти")}
                                        </Button>
                                    </Grid>

                                    {/* <Grid item container>
                                        <Grid xs={12} justify="center" style={{ display: "flex" }}>
                                            <span>{__translate("login.new", "Новый пользователь?")}</span>
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            justify="center"
                                            style={{
                                                display: "flex",
                                                marginTop: "10px",
                                                paddingBottom: "12px",
                                                borderBottom: "#C7C7C7 1px solid",
                                            }}>
                                            <NavLink className={classes.link} to={`/${params.ru}/sign-up`}>
                                                {__translate("login.signup", "Зарегистрируйтесь")}
                                            </NavLink>
                                        </Grid>
                                    </Grid> */}

                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginTop: "16px",
                                        }}>
                                        <span style={{ color: "#00775F", fontWeight: "bold", marginRight: "10px" }}>
                                            {__translate("login.subcribe", "Подписаться на нас в: ")}
                                        </span>
                                        <TgButton
                                            classes={socialClasses}
                                            onClick={() => {
                                                window.open("https://t.me/kazandigitalweek", "_blank");
                                            }}
                                        />
                                        <YoutubeButton
                                            classes={socialClasses}
                                            onClick={() => {
                                                window.open(
                                                    "https://www.youtube.com/channel/UC_Lea7q3d-48qDwG2hPx8Fw",
                                                    "_blank",
                                                );
                                            }}
                                        />
                                    </Grid>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                    {/* <Box mt={8}>
                        <Copyright />
                    </Box> */}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import __translate from "helper/locale/locale";
import KdwNavLink from "./KdwNavLink";
import { useSelector } from "react-redux";
import { reselect_Menu_Status, reselect_Profile_entity } from "controllers/profile-route/redux-store/profile/profile";
import { RegistrationRole, RegistrationRole as RR } from "constants/constants";
import KDWSnackbar from "components/KDWSnackbar/KDWSnackbar";
import ModeratorLinks from "./ModeratorLinks";
import { __localeStorage } from "helper/location/location";
import {
    Assignment as AssignmentIcon,
    KeyboardVoice as KeyboardVoiceIcon,
    LibraryBooks as LibraryBooksIcon,
    Person as PersonIcon,
    RecentActors,
    Star,
    Stars,
} from "@material-ui/icons";

import { ReactComponent as FaceIDIcon } from "../../../styles/img/icon/common/face_ID_logo.svg";
import { getDoc } from "../../../controllers/profile-route/api/profile/profile";
import { reselect_Constants_Roles } from "controllers/constants-route/redux-store/constants/constants";

const localStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    admintitle: {
        fontSize: "18px",
        fontWeight: 600,
        flexGrow: 1,
    },
    leftMenu: {
        background: "#FFF",
        padding: (props: { pageScrollY: number }) => `${95 - props.pageScrollY}px 0.7em 1em 1em`,
        zIndex: 0,
        left: 0,
        top: 0,
        position: "fixed",
        minWidth: "260px",
        maxWidth: "260px",
        transition: "all 0.5s ease",
        overflowX: "hidden",
        [theme.breakpoints.up(958)]: {
            height: "100%",
        },
        [theme.breakpoints.down(958)]: {
            minWidth: "100%",
            position: "relative",
            padding: "1em !important",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
        },
    },
    hideBtn: {
        [theme.breakpoints.down(958)]: {
            display: "none",
        },
    },
    hideMenu: {
        maxWidth: 80,
        minWidth: 80,
        [theme.breakpoints.down(958)]: {
            minWidth: "100%",
        },
    },
}));

type TYPE_UserMenu = {
    visibility: boolean;
    on_Close: () => void;
};
export const userFilled = (user: any, form?: any) => {
    return (
        user &&
        // (Number.isInteger(user.participation_data.role)||Number.isInteger(user.role)) &&
        (user.country || (form && form.country)) &&
        (user.birthday || (form && form.birthday)) &&
        (user.photo || (form && form.photo)) &&
        (user.city || (form && form.city)) &&
        (user.phone || (form && form.phone)) &&
        (user.email || (form && form.email)) &&
        (user.name || (form && form.name)) &&
        (user.country != 'RU' || user.lastname || (form && form.lastname)) &&
        (user.surname || (form && form.surname))
    );
};
const UserLeftMenu: React.FunctionComponent<TYPE_UserMenu> = ({ visibility, on_Close }) => {
    const [pageScrollY, setPageScrollY] = useState(0);
    const classes = localStyles({ pageScrollY });
    const params = useParams<{ ru: string }>();
    const hide = useSelector(reselect_Menu_Status);
    const user = useSelector(reselect_Profile_entity);
    const hasKdwData = !!user?.participation_data?.id;
    const isAdmin = user?.user_type === "admin";
    const isManager = user?.user_type === "manager";
    const role = user?.participation_data?.role;
    const history = useHistory();

    const handleScroll = () => {
        const pageScrollY = window ? window.pageYOffset : 0;
        setPageScrollY(pageScrollY < 95 ? pageScrollY : 95);
    };
    const [snack, setSnack] = React.useState(null as string);

    useEffect(() => {
        document && document.addEventListener("scroll", handleScroll);
        return () => document && document.removeEventListener("scroll", handleScroll);
    }, []);

    const to = (link: string) => {
        if (user && user.user_type === "user") {
            if (!userFilled(user)) {
                return setSnack("profile.required_data");
            } else if (
                link.indexOf("speaker-data") !== -1 &&
                role === RegistrationRole.SPEAKER &&
                !user.participation_data?.status?.state
            ) {
                return setSnack("profile.need_request");
            }
        } else if (hasKdwData && link.indexOf("current") === -1) {
            if (!user?.email_confirmed_at) return setSnack("profile.unconfirmed_curator");
        }
        history.push(link);
    };

    const ru = __localeStorage();
    return (
        <React.Fragment>
            <KDWSnackbar
                isOpen={!!snack}
                severity={"error"}
                handleOpen={() => {
                    setSnack(null);
                }}
                message={snack ? __translate(snack) : null}
            />
            <Grid item xs={12} sm={2} className={classes.leftMenu + (!hide ? "" : " " + classes.hideMenu)}>
                {/*<Grid className={classes.hideBtn}>*/}
                {/*<KdwNavLink*/}
                {/*    includeToActive={null}*/}
                {/*    hide={hide}*/}
                {/*    onClick={(e: any) => {*/}
                {/*        dispatch(updateMenuStatus(!hide));*/}
                {/*    }}*/}
                {/*    to={"#"}*/}
                {/*    icon={() => <ArrowBackIosIcon color="disabled" style={{ maxWidth: 47, paddingLeft: 5 }} />}*/}
                {/*    text={__translate("profile.hide", "Скрыть (меню)")}*/}
                {/*/>*/}
                {/*<hr />*/}
                {/*</Grid>*/}
                <KdwNavLink
                    includeToActive={"/personal-data"}
                    onClick={() => history.push(`/user/personal-data`)}
                    icon={(color: any, className: any) => <PersonIcon color={color} className={className} />}
                    text={__translate("profile.pesonal_data", "Личные данные")}
                />
                {(!isManager || hasKdwData) && (
                    <KdwNavLink
                        includeToActive={"/participations"}
                        onClick={() => to(`/user/participations/current`)}
                        icon={(color: any, className: any) => <AssignmentIcon color={color} className={className} />}
                        text={__translate("profile.participation_requests", "Мои заявки")}
                    />
                )}
                {user &&
                    (role == RR.SPEAKER ||
                        (user?.participation_data?.reports?.length > 0)) && (
                        <>
                            <KdwNavLink
                                includeToActive={"/speaker-data"}
                                onClick={() => to(`/user/speaker-data`)}
                                icon={(color: any, className: any) => (
                                    <PersonIcon color={color} className={className} />
                                )}
                                text={__translate("profile.my_reports", "Мои доклады")}
                            />
                        </>
                    )}
                {(!isManager || hasKdwData) && (
                    <KdwNavLink
                        includeToActive={"user/articles"}
                        onClick={() => to(`/user/articles`)}
                        icon={(color: any, className: any) => <AssignmentIcon color={color} className={className} />}
                        text={__translate("profile.articles", "Мои статьи")}
                    />
                )}
                {(hasKdwData) && (
                    <KdwNavLink
                        includeToActive={"user/startups"}
                        onClick={() => to(`/user/startups`)}
                        icon={(color: any, className: any) => <AssignmentIcon color={color} className={className} />}
                        text={__translate("profile.startups", "Мои стартапы")}
                    />
                )}
                {isAdmin && (
                    <KdwNavLink
                        includeToActive={"/program"}
                        onClick={() => to(`/site/program`)}
                        icon={(color: any, className: any) => <AssignmentIcon color={color} className={className} />}
                        text={__translate("profile.program", "Программа")}
                    />
                )}
                {true && (
                    <KdwNavLink
                        includeToActive={"/translation"}
                        onClick={() => to(`/user/translation`)}
                        icon={(color: any, className: any) => <KeyboardVoiceIcon color={color} className={className} />}
                        text={__translate("profile.translations", "Трансляции")}
                    />
                )}
                {isAdmin && (
                    <KdwNavLink
                        includeToActive={"/exponents"}
                        onClick={() => to(`/site/exponents`)}
                        icon={(color: any, className: any) => <LibraryBooksIcon color={color} className={className} />}
                        text={__translate("profile.catalog", "Каталог")}
                        split={true}
                    />
                )}
                {isAdmin && (
                    <KdwNavLink
                        disabled={true}
                        includeToActive={"/visits"}
                        onClick={() => to(`/user/visits`)}
                        text={__translate("profile.visits", "Мероприятия")}
                        icon={(color: any, className: any) => <Star color={color} className={className} />}
                        split={true}
                    />
                )}
                {isAdmin && (
                    <KdwNavLink
                        disabled={true}
                        includeToActive={"/partners"}
                        onClick={() => to(`/user/partners`)}
                        text={__translate("profile.partners", "Партнеры")}
                        icon={(color: any, className: any) => <RecentActors color={color} className={className} />}
                        split={true}
                    />
                )}
                {/*{(*/}
                {/*    <KdwNavLink*/}
                {/*        includeToActive={"/leader"}*/}
                {/*        onClick={() => to(`/user/ITLeader`)}*/}
                {/*        text={__translate("site.it_leader", "Премия ИТ Лидер")}*/}
                {/*        icon={(color: any, className: any) => <Stars color={color} className={className} />}*/}
                {/*        split={true}*/}
                {/*    />*/}
                {/*)}*/}
                {true && (
                    <KdwNavLink
                        includeToActive={"/biometry"}
                        onClick={() => to(`/user/biometry`)}
                        text={__translate("profile.biometry", "Биометрия")}
                        icon={(color: any, className: any) => <FaceIDIcon className={className} />}
                        split={true}
                    />
                )}
                <KdwNavLink
                    includeToActive={"/asdf"}
                    onClick={() => getDoc('badge')}
                    icon={(color: any, className: any) => <AssignmentIcon color={color} className={className} />}
                    text={__translate("profile.pesonal_badge", "Мой бейдж")}
                />
                <br />
                <KdwNavLink
                    hide={hide}
                    split={true}
                    includeToActive={"/certificate"}
                    onClick={() => getDoc('certificate')}
                    icon={(color: any, className: any) => <AssignmentIcon color={color} className={className} />}
                    text={__translate("profile.cert", "Сертификат участника")}
                />
                <br />
                {/*<KdwNavLink*/}
                {/*    disabled={true}*/}
                {/*    hide={hide}*/}
                {/*    includeToActive={"/contact"}*/}
                {/*    onClick={() => to(`/${params.ru}/user/contacts`)}*/}
                {/*    icon={(color: any, className: any) => <LocalPhoneIcon color={color} className={className} />}*/}
                {/*    text={__translate("profile.contacts", "Контакты")}*/}
                {/*/>*/}

                {user &&
                    user.user_type === "user" &&
                    user.participation_data?.role == RR.PARTICIPANT_PREMIUM &&
                    !user.participation_data?.pay_order_id && (
                        <>
                            <KdwNavLink
                                includeToActive={"/payment"}
                                onClick={() => to(`/${params.ru}/payment`)}
                                icon={(color: any, className: any) => (
                                    <b color={color} className={className} style={{ width: "1em", height: "1em" }}>
                                        ₽
                                    </b>
                                )}
                                text={__translate("profile.payment_title", "Оплата")}
                            />
                        </>
                    )}

                {user?.user_type !== "user" && <ModeratorLinks classes={classes} to={to} />}

                {user && user.companies?.find(el => el.user_id === user.id) && (
                    <>
                        <KdwNavLink
                            includeToActive={"/company-lk"}
                            onClick={() => to(`/user/company-lk/${user.companies?.find(el => el.user_id == user.id).id}`)}
                            icon={(color: any, className: any) => <PersonIcon color={color} className={className} />}
                            text={__translate("profile.company_lk", "ЛК компании")}
                        />
                    </>
                )}
            </Grid>
        </React.Fragment>
    );
};
export default UserLeftMenu;

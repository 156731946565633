import {kyRequest, BACKEND_HOST, responseCallback, API_RouteConstants} from "api/api";

export const __C = {
        user: {
                eat_types: {},
                souvenir_types: {},
                sectors: {},
                forum_themes: {},
                roles2023: {},
                roles: {},
                academic_degrees: {},
                exponent_states: {},
                exponent_types: {},
                event_types: {},
                report_states: {},
                articles_states: {},
                articles_types: {},
                visit_user_statuses: {},
                visit_types: {},
                covid_states: {},
                doc_states: {},
                startup_states: {},
                participations: {},
                badge_types: {},
        } as any,
        manager: {
                roles: {}
        } as any,
        dates: {},
        start_date: (new Date()).getTime(),
        countries: [] as Array<any>,
        org_types: [] as Array<string>,
};

/**
 * @route api/constants
 * @post
 */
export const apiRouteConstants = async () => {
    const response = await kyRequest.get(`${BACKEND_HOST}/constants`);
    return responseCallback(response);
};
